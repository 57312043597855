<template>
    <div v-if="! loading" class="portal-container full-height">
        <v-app-bar
            app
            clipped-left
            flat
            class="primary"
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            <v-spacer />
            <v-btn icon dark @click="invertColors">
                <v-icon>
                    mdi-invert-colors
                </v-icon>
            </v-btn>
            <v-btn
                :disabled="singingOut"
                :loading="singingOut"
                icon
                dark
                @click="logOut"
            >
                <v-icon>
                    mdi-logout
                </v-icon>
            </v-btn>
        </v-app-bar>

        <v-navigation-drawer
            v-model="drawer"
            class="navigation-drawer"
            height="calc(100vh - 84px)"
            floating
            clipped
            app
        >
            <portal-navigation />
        </v-navigation-drawer>

        <v-main class="app-content">
            <v-container fluid>
                <router-view />
            </v-container>
        </v-main>
    </div>
</template>

<style lang="scss">
.navigation-drawer {
    margin: 10px;
    border-radius: 15px;
}
.app-content {
    margin-left: 20px;
}
.v-app-bar {
    .toolbar {
        &__logo {
            max-height: 75%;
        }
    }
}

.full-height {
    height: 100%;
}

</style>

<script>
import PortalNavigation from "@/components/dashboard/PortalNavigation";
import {THEME_PORTAL} from "@/plugins/vuetify";
import {mapStores} from 'pinia';
import {useAuthStore} from "@/stores/AuthStore";
import {useNotificationStore} from "@/stores/NotificationStore";
import axios from 'axios';
import router from "@/router";
export default {
    name: 'StraLayout',
    components: {PortalNavigation},
    data() {
        return {
            drawer: true,
            loading: true,
            singingOut: false
        };
    },

    computed: {
        ...mapStores(useAuthStore, useNotificationStore),
    },

    async mounted() {
        this.$vuetify.theme.themes = THEME_PORTAL;
        // Hacky workaround for vuetify to apply the new theme colors
        this.$vuetify.theme.dark = !! this.$vuetify.theme.dark;

        if (this.authStore.persons === null) {
            await this.authStore.getPersons();
        }
        this.loading = false;
    },

    methods: {
        invertColors() {
            this.$vuetify.theme.dark = ! this.$vuetify.theme.dark;
        },
        async logOut() {
            try {
                this.singingOut = true;
                await axios.post('/auth/logout');
                sessionStorage.removeItem('refresh_token');
                sessionStorage.removeItem('active_person_id');
                router.push({name: 'loginForm'});
            } catch(e) {
                this.notificationStore.setNotification('Er is iets misgegaan', 'error');
                this.singingOut = false;
            }
        },
    }
};

</script>

<template>
    <auth-form>
        <div v-if="! loading">
            Je E-mailadres is bevestigd. <router-link :to="{ name: 'loginForm' }">Log in</router-link> om verder te gaan naar Mijn LCKV.
        </div>
        <div v-else class="loading">
            <v-progress-circular
                indeterminate
                color="#f53c05"
                size="50"
            />
        </div>
    </auth-form>
</template>

<style>
    .loading {
        margin: 20px;
        text-align: center;

    }
</style>

<script>

import AuthForm from "@/components/authorization/AuthForm";
import axios from 'axios';

export default {
    name: 'RegisterConfirm',
    components: {AuthForm},

    data() {
        return {
            loading: true,
        };
    },

    async mounted() {
        await axios.post('/auth/verify', {token: 'ABC123', id: 1});
        this.loading = false;
    }
};

</script>

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import TentIcon from '../components/icons/TentIcon';
import TreeIcon from '../components/icons/TreeIcon';

Vue.use(Vuetify);

export const THEME_PORTAL = {
    light: {
        primary: '#2196f3',
        secondary: '#e91e63',
        accent: '#ff9800',
        error: '#f44336',
        warning: '#ff5722',
        info: '#03a9f4',
        success: '#8bc34a',
    },
    dark: {
        primary: '#003560',
        secondary: '#350817',
        accent: '#ff9800',
        error: '#f44336',
        warning: '#ff5722',
        info: '#03a9f4',
        success: '#8bc34a',
    }
};

export const THEME_LOGIN = {
    light: {
        primary: '#f53c05',
        secondary: '#e91e63',
        accent: '#ff9800',
        error: '#f44336',
        warning: '#ff5722',
        info: '#03a9f4',
        success: '#8bc34a',
    },
    dark: {
        primary: '#f53c05',
        secondary: '#350817',
        accent: '#ff9800',
        error: '#f44336',
        warning: '#ff5722',
        info: '#03a9f4',
        success: '#8bc34a',
    }
};

const opts = {
    theme: {
        defaultTheme: 'light',
        themes: THEME_PORTAL
    },
    icons: {
        values: {
            'lckv-tent': {
                component: TentIcon
            },
            'lckv-tree': {
                component: TreeIcon
            }
        }
    },
};

export default new Vuetify(opts);

<script>

import {mapStores} from 'pinia';
import {useAuthStore} from "@/stores/AuthStore";
import PersonAvatar from "@/components/util/PersonAvatar";

export default {
    name: "PortalNavigation",
    components: {PersonAvatar},
    data() {
        return {
            activeSubmenu: null,
            links: [
                ['mdi-account', 'Mijn gegevens'],
            ],
        };
    },

    computed: {
        ...mapStores(useAuthStore),
    },

    methods: {
        setActive(name) {
            this.activeSubmenu = this.activeSubmenu !== name
                ? name
                : null
            ;
        },
        closeSubmenus() {
            this.activeSubmenu = null;
        },

        navigate(routeName) {
            this.$router.push({ name: routeName });
        }
    }
};

</script>

<template>
    <div>
        <v-sheet v-if="authStore.activePerson" class="person-select pa-4">

            <v-menu bottom left min-width="246">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="change-person-btn"
                        icon
                        small
                        text
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-for="person of authStore.persons"
                        :key="person.id"
                        link
                        @click="authStore.setActivePerson(person.id)"
                    >
                        <v-list-item-title style="display: flex; gap: 10px; align-items: center;">
                            <person-avatar :size="25" :person="person" />
                            <div>{{ authStore.personFullName(person.id) }}</div>
                        </v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <v-list-item link @click="navigate('createPerson')">
                        <v-list-item-title style="display: flex; gap: 10px; align-items: center;">
                            <person-avatar :size="25" text="+" />
                            <div>Persoon toevoegen</div>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <person-avatar
                :key="authStore.activePersonId"
                :size="60"
                :person="authStore.activePerson"
            />
            <div>{{ authStore.personFullName(authStore.activePersonId) }}</div>
        </v-sheet>

        <v-list>
            <v-list-item
                v-for="[icon, text, route] in links"
                :key="icon"
                link
                @click="navigate(route)"
            >
                <v-list-item-icon>
                    <v-icon>{{ icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </div>
</template>

<style lang="scss" scoped>

    .stra-menu {
        display: flex;
        flex-direction: column;
        height: 100%;
        transition: .3s cubic-bezier(.4,0,.2,1);

        .stra-menu__item-wrapper {
            overflow: hidden;
        }

        .stra-menu__item-wrapper:not(.stra-menu__item-wrapper--open) {
            flex: 0 1 48px;
            min-height: 48px;
        }
        .stra-menu__item-wrapper--open {
            flex-grow: 1;
        }
    }

    .person-select {
        display: flex;
        gap: 10px;
        align-items: center;
        flex-direction: column;
    }

    .change-person-btn {
        position: absolute;
        right: 5px;
        top: 5px;
    }
</style>

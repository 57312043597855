<template>
    <div class="login__wrapper">
        <v-card class="login__card rounded-xl" :class="loginFormClass" :loading="loading">
            <div class="form__wrapper" style="padding: 20px; height: 100%;">
                <v-card-title style="justify-content: center">
                    <div class="login__logo">
                        <img alt="Logo LCKV Jeugdvakanties" class="login__logo-img" src="@/assets/images/logo_red.svg">
                    </div>
                </v-card-title>
                <v-card-text class="login__form pb-0">
                    <slot />
                </v-card-text>
            </div>
        </v-card>
        <v-card class="login__card form__description rounded-xl" :class="[{ 'login__card--hidden': ! $slots.formDescription }, loginDescriptionClass]">
            <slot name="formDescription" />
        </v-card>
    </div>
</template>

<script>
export default {
    name: "AuthForm",

    props: {
        loginFormClass:        { type: String, default: null },
        loginDescriptionClass: { type: String, default: null },
        loading:               { type: Boolean, default: false }
    }
};
</script>

<style lang="scss">
@import '@/scss/_breakpoints.scss';
$breakpoint-login-sm: '768px';

.form{
    &__button {
        width: 100%;
        transition: width .5s, border-radius .5s, background-color .5s !important;
        margin-left: auto;
        margin-right: auto;
        display: block !important;

        &--pending {
            width: 36px;
            min-width: unset !important;
            padding: 0 !important;
            border-radius: 20px !important;
        }
    }
    &__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__footer-link {
        text-align: center;
        font-size: 1em;
    }
    &__description {
        box-shadow: #000000;
    }
}

.theme--dark {
    .form__footer-link {
        color: #aaaaaa !important;
    }
}

.theme--light {
    .form__footer-link {
        color: #555555 !important;
    }
}

.login {
    &__wrapper {
        transition: width .3s;
        display: flex;
        gap: 20px;
        @media (max-width: $breakpoint-login-sm) {
            width: 100%;
        }
        @media (max-width: $breakpoint-lt-md) {
            width: 100%;
            justify-content: center ;
        }
        @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lt-lg) {
            width: 75%;
            justify-content: flex-end;
        }
        @media (min-width: $breakpoint-lg) and (max-width: $breakpoint-lt-xl) {
            width: 60%;
            justify-content: flex-end;
        }
        @media (min-width: $breakpoint-xl) {
            width: 50%;
            justify-content: flex-end;
        }
    }
    &__logo-img {
        width: 210px;
    }

    &__card {
        width: 350px;
        display: flex;
        flex-direction: column;
        min-height: 500px;

        &--hidden {
            @media (max-width: $breakpoint-login-sm) {
                display: none;
            }
            @media (min-width: $breakpoint-sm) {
                visibility: hidden;
            }
        }
        @media (max-width: $breakpoint-login-sm) {
            &--hidden-sm {
                display: none;
            }
        }

        @media (max-width: $breakpoint-lt-lg) {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }
    }

    &__form {
        flex: 1;
    }
}
</style>

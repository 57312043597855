<template>
    <div>Welkom, ...</div>
</template>

<style scoped>

</style>

<script>
import {useAuthStore} from "@/stores/AuthStore";
import { mapStores } from 'pinia';

export default {
    name: "PortalDashboard",

    computed: {
        ...mapStores(useAuthStore),
    },
};
</script>

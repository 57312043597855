import axios from 'axios';
import {useAuthStore} from "@/stores/AuthStore";

axios.interceptors.request.use((config) => {
    const authStore = useAuthStore();
    config.headers.Authorization = `${authStore.accessToken}`;
    config.headers.Accept = "application/json";
    return config;
});

axios.defaults.baseURL = process.env.VUE_APP_API_ROUTE;
axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');

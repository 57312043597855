<script>
import AuthForm from "@/components/authorization/AuthForm";
import axios from 'axios';
import { mapStores } from 'pinia';
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators';
import Validation from "@/mixins/Validation";
import {ERROR_GENERIC, ERROR_RESET_TOKEN_INVALID, ERROR_UNAUTHENTICATED} from "@/mixins/ErrorCodes";
import {useNotificationStore} from "@/stores/NotificationStore";

export default {
    name: "ResetPassword",
    components: {AuthForm},
    mixins: [ Validation ],

    data() {
        return {
            email:         null,
            pending:       false,
            password:      null,
            passwordCheck: null,
            resetToken:    null,
            success:       false,
        };
    },

    computed: {
        ...mapStores(useNotificationStore),

        btnColor() {
            return this.$vuetify.theme.dark
                ? 'black'
                : 'primary'
            ;
        }
    },

    validations() {
        return {
            email:         { required, email },
            password:      { required, minLength: minLength(6) },
            passwordCheck: { sameAs: sameAs('password') },
        };
    },

    mounted() {
        this.resetToken = this.$route.query.token;
    //     this.$router.replace({ query: null });
    },

    methods: {
        async handleSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) return;

            this.pending = true;
            const params = {
                email:            this.email,
                reset_token:      this.resetToken,
                wachtwoord:       this.password,
                wachtwoord_check: this.passwordCheck
            };
            try {
                let response = await axios.post('/auth/password/reset', params);
                if (response.data.error) {
                    if (response.data.errors.code === ERROR_RESET_TOKEN_INVALID) {
                        this.notificationStore.setNotification("Verkeerd E-mailadres.", "error");
                    }
                    if (response.data.errors.code === ERROR_GENERIC) {
                        let message = '';
                        for (let i of Object.keys(response.data.errors.message)) {
                            message += response.data.errors.message[i].join(', ') + ', ';
                        }
                        this.notificationStore.setNotification(message, "error", { multiLine: true });
                    }
                } else {
                    this.success = true;
                }
            } catch(e) {
                //eslint-disable-next-line no-console
                console.error(e);
            } finally {
                this.pending = false;
            }
        }
    }
};
</script>

<template>
    <auth-form>
        <div class="form__wrapper">
            <v-form
                v-if="! success"
                :disabled="pending"
                @submit.prevent="handleSubmit"
            >
                <v-text-field
                    v-model="email"
                    type="email"
                    :disabled="pending"
                    :error-messages="getError('email')"
                    label="E-mailadres"
                    hide-details="auto"
                    autofocus
                />
                <v-text-field
                    v-model="password"
                    :disabled="pending"
                    :error-messages="getError('password')"
                    label="Wachtwoord"
                    type="password"
                    hide-details="auto"
                />
                <v-text-field
                    v-model="passwordCheck"
                    :disabled="pending"
                    :error-messages="getError('passwordCheck')"
                    label="Wachtwoord bevestigen"
                    type="password"
                    hide-details="auto"
                />
                <v-btn
                    :class="{ 'form__button--pending': pending }"
                    :disabled="pending"
                    type="submit"
                    :color="btnColor"
                    elevation="0"
                    class="form__button"
                >
                    <template v-if="! pending">
                        Versturen
                    </template>
                    <v-progress-circular
                        v-else
                        indeterminate
                        color="#ffffff"
                        size="20"
                    />
                </v-btn>
            </v-form>
            <div v-else>
                Je wachtwoord is succesvol ge-reset.<br>
                <router-link :to="{name: 'loginForm'}">
                    Log in
                </router-link>
                om door te gaan naar Mijn LCKV
            </div>
        </div>
    </auth-form>
</template>

<style scoped>

</style>

<script>
import AuthForm from "@/components/authorization/AuthForm";
import PersonAvatar from "@/components/util/PersonAvatar";
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/AuthStore';
import router from "@/router";

export default {
    name: "SelectPerson",
    components: {PersonAvatar, AuthForm},

    computed: {
        ...mapStores(useAuthStore),
    },

    async mounted() {
        if (this.authStore.persons === null) {
            await this.authStore.getPersons();
        }
    },

    methods: {
        setActivePerson(person) {
            this.authStore.setActivePerson(person.id);
            router.push({ name: 'dashboard'});
        },

        handleAddPerson() {
            router.push({ name: 'loginCreatePerson' });
        }
    }
};
</script>

<template>
    <auth-form>
        <div class="list-description">Als welke persoon wil je verder gaan?</div>
        <ul class="person__list">
            <li
                v-for="(person, index) of authStore.persons"
                :key="`person-${index}`"
                class="person__wrapper"
                @click="setActivePerson(person)"
            >
                <person-avatar :size="40" :person="{ voornaam: person.voornaam, achternaam: person.achternaam }" />
                <div class="person__content">
                    <b>{{ authStore.personFullName(person.id) }}</b>
                    <span>{{ person.geboortedatum }}</span>
                </div>
            </li>
            <li class="person__wrapper person__wrapper--new" @click="handleAddPerson">
                <v-icon class="add-person__icon">mdi-plus</v-icon>
                <div class="person__content">
                    <b>Persoon toevoegen</b>
                </div>
            </li>
        </ul>
    </auth-form>
</template>

<style lang="scss" scoped>
    .list-description {
        text-align: center;
        margin-bottom: 10px;
    }
    .person {
        &__list {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 0;
            padding: 0;
        }
        &__wrapper {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 1em;
            border-radius: 5px;
            border-width: 2px;
            transition-duration: 1s;
            cursor: pointer;

            &:not(&--new) {
                border-style: solid;
            }

            &--new {
                border-style: dashed;
            }

            &:hover {
                box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 20%);
                border-color: transparent;
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
        }
    }

    .add-person__icon {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background: #eee;
    }

    .theme--light {
        .person__wrapper:not(:hover) {
            border-color: #eee;
        }
    }

    .theme--dark {
        .person__wrapper:not(:hover) {
            border-color: #111;
        }
    }
</style>

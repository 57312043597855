<script>
import AuthForm from "@/components/authorization/AuthForm";
import axios from 'axios';
import Validation from "@/mixins/Validation";
import { required, email } from 'vuelidate/lib/validators';

export default {
    name: "ForgotPassword",
    components: {AuthForm},
    mixins: [ Validation ],

    data() {
        return {
            formSuccess: false,
            email   :    '',
            pending:     false,
        };
    },

    computed: {
        btnColor() {
            return this.$vuetify.theme.dark
                ? 'black'
                : 'primary'
            ;
        }
    },

    validations: {
        email:         { required, email },
    },

    methods:  {
        async handleSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) return;

            this.pending = true;
            try {
                // This call will always respond with no error, even when the email does not exist.
                // Therefore, no need to do error handling
                await axios.post('/auth/password/request', { email: this.email });
                this.formSuccess = true;
            } catch (e) {
                //eslint-disable-next-line no-console
                console.error(e);
            } finally {
                this.pending = false;
            }
        }
    }
};
</script>

<template>
    <auth-form>
        <div class="form__wrapper">
            <v-form v-if="! formSuccess">
                <div class="forgot-text">
                    We mailen je instructies om je wachtwoord te resetten.
                </div>
                <form @submit.prevent="handleSubmit">
                    <v-text-field
                        v-model="email"
                        :disabled="pending"
                        :error-messages="getError('email')"
                        label="E-mailadres"
                    />
                    <v-btn
                        :class="{ 'form__button--pending': pending }"
                        :disabled="pending"
                        type="submit"
                        class="form__button"
                        elevation="0"
                        :color="btnColor"
                    >
                        <template v-if="! pending">
                            Versturen
                        </template>
                        <v-progress-circular
                            v-else
                            indeterminate
                            color="#ffffff"
                            size="20"
                        />
                    </v-btn>
                </form>
            </v-form>
            <div v-else>
                We hebben je een E-mail gestuurd met instructies om je wachtwoord te resetten.
            </div>
            <div v-if="! pending" style="text-align: center">
                <router-link :to="{ name: 'loginForm' }" class="form__footer-link">
                    Inloggen
                </router-link>
            </div>
        </div>
    </auth-form>
</template>

<style scoped>

</style>

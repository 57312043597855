import Vue from 'vue';
import App from './App.vue';
import './axios';
import vuetify from '@/plugins/vuetify';

import 'material-design-icons-iconfont/dist/material-design-icons.css';

// Pinia
import { createPinia, PiniaVuePlugin } from 'pinia';
Vue.use(PiniaVuePlugin);
export const pinia = createPinia();

// Router
import VueRouter from 'vue-router';
import router from './router';
Vue.use(VueRouter);

// Vuelidate
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

new Vue({
    pinia,
    router,
    vuetify,
    render: h => h(App),
}).$mount("#app");

<template>
    <div class="login-page__wrapper">
        <router-view />
    </div>
</template>

<style lang="scss">
@import '@/scss/_breakpoints.scss';

.theme--light {
    --login-background: url('@/assets/images/login.jpg');
}
.theme--dark {
    --login-background: url('@/assets/images/login_dark.jpg');
}

.login-page__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 2em;
    background: {
        image: var(--login-background);
        size: cover;
    }
    @media (min-width: $breakpoint-lg) {
        background-position: center center;
    }
    @media (max-width: $breakpoint-lt-lg) {
        background-position: right center;
    }
}
</style>

<script>
import {THEME_LOGIN} from "@/plugins/vuetify";

export default {
    name: "LoginView",

    data() {
        return {
            loading: true,
        };
    },

    mounted() {
        this.$vuetify.theme.themes = THEME_LOGIN;
        // Hacky workaround for vuetify to apply the new theme colors
        this.$vuetify.theme.dark = !! this.$vuetify.theme.dark;
    }
};
</script>

import { defineStore } from "pinia";

export const useNotificationStore = defineStore('notification', {
    state: () => ({
        active:   false,
        params:   {},
        priority: '',
        message:  '',
        queue:    [],
    }),
    actions: {
        setNotification(message, priority = 'info', params = {}) {
            this.queue.push({message, priority, params});
            this.handleQueue();
        },
        handleQueue() {
            if (! this.active && this.queue.length > 0) {
                let notification = this.queue.shift();
                this.message     = notification.message;
                this.params      = notification.params;
                this.priority    = notification.priority;
                this.active      = true;
            }
        },
        close() {
            this.active = false;
            this.handleClose();
        },
        // Called when the snackbar is closed via @input in app.vue
        handleClose(active) {
            if (! active) {
                this.reset();

                setTimeout(() => {
                    this.handleQueue();
                }, 300);
            }
        },
        async reset() {
            this.active   = false;
            setTimeout(() => {
                this.message = '';
                this.priority = '';
            },200);
        }
    },

    getters: {
        color() {
            switch (this.priority) {
                case 'error':
                    return 'red';
                case 'warn':
                    return 'orange';
                default:
                    return null;
            }
        },
        button() {
            let defaultAction = { text: 'Sluiten', callback: this.close };

            if (this.params.closeBtn === false && ! this.params.action) {
                return null;
            }

            if (this.params.action) {
                // Check if the action is valid (text and callback are required)
                if (! this.params.action.text || ! this.params.action.callback) {
                    console.error("action.text and action.callback are required when using and snackbar action.");
                    return defaultAction;
                }
                return this.params.action;
            }

            return defaultAction;
        }
    }
});

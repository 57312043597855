import {useAuthStore} from "@/stores/AuthStore";

/**
 * @returns {boolean}
 */
export default async function() {

    const authStore   = useAuthStore();

    if (! authStore.accessToken) {
        if (sessionStorage.getItem('refresh_token')) {
            if (! await authStore.refresh() ) {
                // Can't refresh auth_token (refresh token is probably invalid/outdated)
                return false;
            }
        } else {
            // No auth token or refresh token found
            return false;
        }
    }
    const jwt     = authStore.accessToken.split(" ")[1];
    const payload = jwt.split(".")[1];
    const json    = JSON.parse(atob(payload));
    const now     = new Date();
    return ((json.exp * 1000) > now.getTime());
}

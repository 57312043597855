import VueRouter from 'vue-router';
import PortalView from "@/views/PortalView";
import LoginForm from "@/components/authorization/LoginForm";
import AuthGuard from "@/middlewares/AuthGuard";
import LoginView from "@/views/LoginView";
import ForgotPassword from "@/components/authorization/ForgotPassword";
import RegisterAccount from "@/components/authorization/RegisterAccount";
import ResetPassword from "@/components/authorization/ResetPassword";
import RegisterVerify from "@/components/authorization/RegisterVerify";
import SelectPerson from "@/components/authorization/SelectPerson";
import PortalDashboard from "@/components/dashboard/PortalDashboard";
import PersonEdit from "@/components/person/PersonEdit";
import {useAuthStore} from "@/stores/AuthStore";

/**
 * @param {string} prefix
 * @param {object} routes
 * @returns {object}
 */
function prefixRoutes(prefix, routes) {
    return routes.map(route => Object.assign({}, route, {path: `${prefix}/${route.path}`}));
}

export default new VueRouter({
    routes: [
        {
            path: '/',
            component: PortalView,
            children: [
                {
                    name: 'dashboard',
                    path: '/',
                    component: PortalDashboard,
                },
                ...prefixRoutes('persoon', [
                    {
                        name: 'createPerson',
                        path: 'nieuw',
                        component: PersonEdit,
                    },
                ]),

            ],
            beforeEnter: async (to, from, next) => {
                if (! await AuthGuard()) {
                    next({ name: 'loginForm' });
                } else {
                    if (! sessionStorage.getItem("active_person_id")) {
                        next({ name: 'selectPerson' });
                    } else {
                        next();
                    }
                }
            }
        },
        {
            path: '/login',
            component: LoginView,
            children: [
                {
                    name: 'loginForm',
                    path: '/',
                    component: LoginForm,
                },
                {
                    name: 'forgotPassword',
                    path: 'vergeten',
                    component: ForgotPassword,
                },
                {
                    name: 'resetPassword',
                    path: 'reset',
                    component: ResetPassword,
                },
                {
                    name: 'register',
                    path: 'registreren',
                    component: RegisterAccount,
                },
                {
                    name: 'verify',
                    path: 'verifieren',
                    component: RegisterVerify,
                },
                {
                    name: 'selectPerson',
                    path: 'persoon',
                    component: SelectPerson,
                },
                {
                    name: 'loginCreatePerson',
                    path: 'persoon/nieuw',
                    component: PersonEdit,
                }
            ],
            beforeEnter: async (to, from, next) => {
                let excluded = ['selectPerson', 'loginCreatePerson'];

                if (excluded.indexOf(to.name) === -1 && await AuthGuard()) {
                    next({ name: 'dashboard' });
                } else {
                    next();
                }
            }
        },
    ],
    mode: 'history'
});

<script>
export default {
    name: 'PersonAvatar',

    props: {
        person: { type: Object, default: null },
        text:   { type: String, default: null },
        size:   { type: Number, default: 40 },
    },

    data() {
        return {
            displayText: null,
            color:       'red',
        };
    },

    mounted() {
        if (this.text) {
            this.displayText = this.text;
        } else if (this.person) {
            this.displayText = `${this.person.voornaam[0]}${this.person.achternaam[0]}`;
        }
        this.color = this.stringToHslColor(this.displayText, 50, 80);
    },

    methods: {
        stringToHslColor(str, s, l) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }

            var h = hash % 360;
            return `hsl(${h}, ${s}%, ${l}%)`;
        }
    }
};
</script>

<template>
    <v-avatar :size="size" :color="color">
        <span :style="`font-size: ${size / 2}px`">{{ displayText }}</span>
    </v-avatar>
</template>

<style scoped>
</style>

import { defineStore } from "pinia";
import axios from "axios";
import authGuard from "@/middlewares/AuthGuard";

export const useAuthStore = defineStore('auth', {
    state: () => ({
        accessToken:    null,
        activePersonId: null,
        expireDate:     null,
        loggedIn:       false,
        permissions:    [],
        persons:        null,
        refreshToken:   null,
        activeRefreshCall: null,
    }),
    actions: {
        personFullName(id) {
            let person = this.persons.find(obj => obj.id === id);
            if (person) {
                return person.tussenvoegsels
                    ? `${person.voornaam} ${person.tussenvoegsels} ${person.achternaam}`
                    : `${person.voornaam} ${person.achternaam}`
                ;
            }
            return '';
        },

        signIn(data) {
            this.accessToken   = data.access_token;
            this.expire        = data.expire;
            this.permissions   = data.permissions;
            this.loggedIn      = true;
            sessionStorage.setItem('refresh_token', data.refresh_token);
        },

        async refresh() {
            if (this.activeRefreshCall) {
                return await this.activeRefreshCall();
            }
            try {
                this.activeRefreshCall = await axios.post('/auth/refresh', { refresh_token: sessionStorage.getItem('refresh_token') });
                this.signIn(this.activeRefreshCall.data.data);
                this.activeRefreshCall = null;
                return true;
            } catch(e) {
                sessionStorage.removeItem('access_token');
                return false;
            }
        },

        setActivePerson(id) {
            this.activePersonId = id;
            sessionStorage.setItem('active_person_id', id);
        },
        async getPersons() {
            if (this.loggedIn) {
                let me       = await axios.post('/auth/me?include=personen');
                this.persons = me.data.data.personen;
            }
            return this.persons;
        }
    },

    getters: {
        activePerson() {
            if (! this.persons) {
                return null;
            }
            if (! this.activePersonId) {
                this.activePersonId = sessionStorage.getItem('active_person_id');
            }
            return this.persons.find(person => person.id === this.activePersonId);
        }
    }
});

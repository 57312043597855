<script>

import axios from 'axios';
import AuthForm from "@/components/authorization/AuthForm";
import Validation from "@/mixins/Validation";
import { required, email } from 'vuelidate/lib/validators';
import { mapStores } from "pinia";
import { useNotificationStore } from "@/stores/NotificationStore";
import {ERROR_UNAUTHENTICATED} from "@/mixins/ErrorCodes";
import router from "@/router";
import {useAuthStore} from "@/stores/AuthStore";

export default {
    name: "LoginForm",
    components: {AuthForm},
    mixins: [ Validation ],

    data() {
        return {
            email:      '',
            loading:    true,
            password:   '',
            pending:    false,
            tfaCode:    '',
            tfaToken:   '',
            validated:  null,
            wrongLogin: false,
        };
    },

    computed: {
        ...mapStores(useNotificationStore, useAuthStore),

        btnColor() {
            return this.$vuetify.theme.dark
                ? 'black'
                : 'primary'
            ;
        }
    },

    validations: {
        email:         { required, email },
        password:      { required },
    },

    async mounted() {
        // If there is a query parameter called "token", we assume the user wants to verify his/her account
        let token = this.$route.query.token;
        if (token) {
            try {
                await axios.post('/auth/verify', {token});
                this.validated = true;
            } catch(e) {
                this.validated = false;
            }
        }
        this.loading = false;
    },

    methods: {
        async handleLogin() {
            this.$v.$touch();
            if (this.$v.$invalid) return;

            this.pending = true;
            try {
                let response = await axios.post('auth/login', { email: this.email, wachtwoord: this.password });
                // TODO: In the future the backend returns an error code, so handle this in the catch()
                if (response.data.error) {
                    if (response.data.errors.code === ERROR_UNAUTHENTICATED) {
                        this.notificationStore.setNotification("Gebruikersnaam/wachtwoord komt niet overeen.", "error");
                    }
                } else {
                    this.authStore.signIn(response.data.data);
                    await this.$nextTick();
                    await this.authStore.getPersons();

                    if (this.authStore.persons && this.authStore.persons.length > 0) {
                        if (this.authStore.persons.length === 1) {
                            this.authStore.setActivePerson(this.authStore.persons[0].id);
                            router.push({name: 'dashboard'});
                        } else {
                            router.push({ name: 'selectPerson' });
                        }
                    } else {
                        router.push({ name: 'loginCreatePerson' });
                    }
                }
            } catch(e) {
                //eslint-disable-next-line no-console
                console.error(e);
            } finally {
                this.pending = false;
            }
        }
    }
};
</script>

<template>
    <auth-form :loading="loading">
        <div class="form__wrapper">
            <template v-if="! loading">
                <div>
                    <template v-if="validated !== null">
                        <div v-if="validated">
                            Je E-mailadres is succesvol gevalideerd. Je kan nu inloggen met het onderstaande formulier.
                        </div>
                        <div v-else>
                            Je E-mailaders kon niet worden gevalideerd.
                        </div>
                    </template>
                    <v-form @submit.prevent="handleLogin">
                        <v-text-field
                            v-model="email"
                            :disabled="pending"
                            :error-messages="getError('email')"
                            label="E-mailadres"
                            hide-details="auto"
                            autofocus
                        />
                        <v-text-field
                            v-model="password"
                            :disabled="pending"
                            :error-messages="getError('password')"
                            label="Wachtwoord"
                            type="password"
                        />
                        <v-btn
                            :class="{ 'form__button--pending': pending }"
                            :disabled="pending"
                            type="submit"
                            :color="btnColor"
                            elevation="0"
                            class="form__button"
                        >
                            <template v-if="! pending">
                                Inloggen
                            </template>
                            <v-progress-circular
                                v-else
                                indeterminate
                                color="#ffffff"
                                size="20"
                            />
                        </v-btn>
                        <Transition name="fade">
                            <div v-if="! pending" class="register">
                                Nog geen account?
                                <router-link :to="{ name: 'register' }">
                                    Registreren
                                </router-link>.
                            </div>
                        </Transition>
                    </v-form>
                </div>
                <Transition name="fade">
                    <div v-if="! pending" style="text-align: center">
                        <router-link :to="{ name: 'forgotPassword' }" class="form__footer-link">
                            Wachtwoord vergeten?
                        </router-link>
                    </div>
                </Transition>

                <!-- <div v-show="! pending && !! tfaToken" @submit.prevent="handleTfa">
                    Vul de code van je authenticator app in:
                    <v-otp-input
                        ref="tfaInput"
                        v-model="tfaCode"
                        autofocus
                        @keyup.enter="handleTfa"
                    />
                    <v-btn
                        elevation="0"
                        class="form__button"
                        dark
                        @click="handleTfa"
                    >
                        Verifiëren
                    </v-btn>
                </div> -->
            </template>
        </div>
    </auth-form>
</template>

<style lang="scss" scoped>

.register {
    text-align: center;
    margin-top: 15px;
}

.fade-leave-active {
    transition-duration: .3s;
}

.fade-leave-to {
    opacity: 0;
}

.fade-leave-from {
    opacity: 1;
}
</style>

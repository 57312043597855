<script>

import {useNotificationStore} from "@/stores/NotificationStore";
import {mapStores} from "pinia";

export default {
    name: 'App',

    data() {
        return {
            drawer: true,
        };
    },

    computed: {
        ...mapStores(useNotificationStore)
    },

    async mounted() {
        this.setTheme();
    },


    methods: {
        setTheme() {
            //check if browser supports dark mode.
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme)').media !== 'not all') {

                const darkModePreference = window.matchMedia("(prefers-color-scheme: dark)");
                this.$vuetify.theme.dark = darkModePreference.matches;

                darkModePreference.addEventListener("change", e => { this.$vuetify.theme.dark = e.matches; });
            }
        }
    }
};
</script>

<template>
    <v-app class="portal-app">
        <router-view />
        <v-snackbar
            v-if="notificationStore"
            v-model="notificationStore.active"
            v-bind="notificationStore.params"
            :color="notificationStore.color"
            @input="notificationStore.handleClose"
        >
            {{ notificationStore.message }}

            <template #action="{ attrs }">
                <v-btn
                    v-if="notificationStore.button"
                    text
                    v-bind="attrs"
                    @click="notificationStore.button.callback"
                >
                    {{ notificationStore.button.text }}
                </v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>

<style lang="scss">
@import "assets/variables";

.theme--light.v-application {
    background-color: var(--app-background-color) !important;
}
</style>

<template>
    <v-card class="form-wrapper" elevation="0" :loading="loading">
        <v-card-title>Persoon toevoegen</v-card-title>
        <v-card-text>
            Aan een account kunnen één of meerdere personen worden gekoppeld.
            Als ouder kunt u dus meerdere kinderen inschrijven met 1 account.
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <v-text-field
                    v-model="firstName"
                    label="Voornaam"
                    :error-messages="getFieldError('voornaam')"
                    required
                />
                <v-text-field
                    v-model="infix"
                    label="Tussenvoegsel"
                    :error-messages="getFieldError('tussenvoegsel')"
                    required
                />
                <v-text-field
                    v-model="lastName"
                    label="Achternaam"
                    :error-messages="getFieldError('achternaam')"
                    required
                />
                <v-menu
                    ref="menu"
                    v-model="datepicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="birthday"
                            label="Geboortedatum"
                            append-icon="mdi-calendar"
                            readonly
                            :error-messages="getFieldError('geboortedatum')"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="birthday"
                        :active-picker.sync="activePicker"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        @change="handleDateChange"
                    ></v-date-picker>
                </v-menu>

                <v-select
                    v-model="gender"
                    :items="['Man', 'Vrouw']"
                    :error-messages="getFieldError('geslacht')"
                    label="Geslacht"
                />
            </v-form>
        </v-card-text>
        <v-card-actions class="form-actions">
            <v-btn text :disabled="loading">
                Annuleren
            </v-btn>

            <v-btn elevation="0"
                   color="primary"
                   :disabled="loading"
                   @click="savePerson"
            >
                Opslaan
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<style scoped>

.form-wrapper {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
}

.form-actions {
    justify-content: flex-end;
}

</style>

<script>
import axios from 'axios';
import { mapStores } from "pinia";
import {useNotificationStore} from "@/stores/NotificationStore";
import router from "@/router";
import {useAuthStore} from "@/stores/AuthStore";

export default {
    name: "PersonCreate",

    data() {
        return {
            loading:      false,
            valid:        true,
            firstName:    null,
            infix:        null,
            lastName:     null,
            birthday:     null,
            gender:       null,
            datepicker:   false,
            activePicker: null,
            errors:       {},
        };
    },

    computed: {
        ...mapStores(useNotificationStore, useAuthStore),
    },

    watch: {
        datepicker (val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'));
        },
    },
    methods: {
        handleDateChange (date) {
            this.datepicker = false;
        },

        async savePerson() {
            // TODO: Validation
            this.loading = true;
            let gender = this.gender;
            if (this.gender === 'Man') {
                gender = 'm';
            } else if (this.gender === 'Vrouw') {
                gender = 'v';
            }

            try {
                let response = await axios.post('/portaal/persoon', {
                    voornaam:       this.firstName,
                    tussenvoegsels: this.infix,
                    achternaam:     this.lastName,
                    geboortedatum:  this.birthday,
                    geslacht:       gender
                });

                if (response.data.error && response.data.errors) {
                    if  (response.data.errors.code === 1001) {
                        const fields = Object.keys(response.data.errors.message).map((field) => field.charAt(0).toUpperCase() + field.slice(1));
                        let message = fields.length === 1
                            ? `Het veld ${fields[0]} is niet juist ingevuld.`
                            : `De velden ${fields.join(", ")} zijn niet juist ingevuld.`
                        ;
                        this.notificationStore.setNotification(message, "error");
                        this.errors = response.data.errors.message;
                    } else {
                        this.notificationStore.setNotification(`Er is iets misgegaan (${response.data.errors.code}).`, "error");
                    }
                } else {
                    this.authStore.persons.push(response.data.data);
                    this.authStore.setActivePerson(response.data.data.id);
                    router.push({ name: 'dashboard' });
                }
            } catch(e) {
                // TODO: Notification
                console.error(e);
            } finally {
                this.loading = false;
            }
        },

        getFieldError(field) {
            if (this.errors && this.errors[field]){
                return this.errors[field];
            }
            return null;
        }
    },
};
</script>

<template>
    <auth-form
        :login-form-class="! acceptEmail ? 'login__card--hidden-sm' : ''"
        :login-description-class="acceptEmail ? 'login__card--hidden-sm' : ''"
    >
        <div class="form__wrapper">
            <v-form
                v-if="! formSuccess"
                ref="registration_form"
                :disabled="! acceptEmail"
                @submit.prevent="handleSubmit"
            >
                <v-text-field
                    v-model="email"
                    :disabled="pending"
                    :error-messages="emailError"
                    type="email"
                    label="E-mailadres"
                    persistent-hint
                    hint="Ouder/verzorger"
                    autofocus
                />
                <v-text-field
                    v-model="password"
                    :disabled="pending"
                    :error-messages="getError('password')"
                    label="Wachtwoord"
                    type="password"
                    hide-details="auto"
                />
                <v-text-field
                    v-model="passwordCheck"
                    :disabled="pending"
                    :error-messages="getError('passwordCheck')"
                    label="Wachtwoord bevestigen"
                    type="password"
                    hide-details="auto"
                />
                <v-checkbox v-model="acceptEmail" class="register__checkbox register__show-sm" label="Ik snap dat ik een E-mailadres van een ouder/verzorger in moet vullen." />
                <v-btn
                    :class="{ 'form__button--pending': pending }"
                    :disabled="pending || ! acceptEmail"
                    type="submit"
                    :color="btnColor"
                    elevation="0"
                    class="form__button"
                >
                    <template v-if="! pending">
                        Registreren
                    </template>
                    <v-progress-circular
                        v-else
                        indeterminate
                        color="#ffffff"
                        size="20"
                    />
                </v-btn>
            </v-form>
            <div v-else>
                <p>Het account is succesvol aangemaakt maar moet nog worden geactiveerd.</p>
                <p>Klik op de link in de bevestigingsmail die we hebben gestuurd naar {{ email }}.</p>
            </div>
            <Transition name="fade">
                <div v-if="! pending" style="text-align: center">
                    <router-link :to="{ name: 'loginForm' }" class="form__footer-link">
                        Inloggen
                    </router-link>
                </div>
            </Transition>
        </div>

        <template v-if="! formSuccess" #formDescription>
            <v-card-title class="primary--text">
                Registreren
            </v-card-title>
            <v-card-text>
                <div class="mb-2">
                    Met dit formulier kan je een account aanmaken voor mijn.lckv.nl.
                    <b>Let hierbij op het volgende</b>
                </div>
                <ul class="register-list">
                    <li>Naar het E-mailadres dat je hier invult wordt alle belangrijke informatie zoals <b>financiële gegevens</b> en <b>vertrektijden</b> verstuurd.</li>
                    <li>Een account kan meerdere deelnemers (kinderen) bevatten</li>
                    <li>Zorg er bij voorkeur voor dat dit een <b>privé E-mailadres</b> is, en geen werk <nobr>E-mailadres</nobr></li>
                </ul>
                <v-checkbox
                    v-model="acceptEmail"
                    :disabled="pending"
                    class="register__checkbox"
                    label="Ik snap dat ik een E-mailadres van een ouder/verzorger in moet vullen."
                />
            </v-card-text>
        </template>

        <v-dialog
            v-model="dialog"
            persistent
            max-width="350"
        >
            <v-card>
                <v-card-title class="text-h5">
                    E-mailadres al in gebruik
                </v-card-title>
                <v-card-text>
                    Dit E-mailadres is al in gebruik op mijn.lckv.nl.
                    Heeft u na 2023 een account voor mijn.lckv.nl aangemaakt?
                    Dan kunt u dit account alle volgende jaren gebruiken, u hoeft dus niet voor ieder seizoen een nieuw account aan te maken.
                    <br><br>
                    Aan een account kunnen meerdere deelnemers worden gekoppeld.
                    Wilt u dus meerdere kinderen inschrijven, dan kan dit vanuit hetzelfde account.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                    >
                        Sluiten
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </auth-form>
</template>

<script>

import axios from 'axios';
import AuthForm from "@/components/authorization/AuthForm";
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators';
import Validation from "@/mixins/Validation";
import { ERROR_EMAIL_ALREADY_IN_USE } from "@/mixins/ErrorCodes";
import { useNotificationStore } from "@/stores/NotificationStore";
import { mapStores } from 'pinia';

export default {
    name: "RegisterAccount",
    components: {AuthForm},
    mixins: [ Validation ],

    data() {
        return {
            acceptEmail:   false,
            dialog:        false,
            dialogShown:   false,
            email:         '',
            emailInUse:    false,
            formError:     null,
            formSuccess:   false,
            loading:       true,
            password:      '',
            passwordCheck: '',
            pending:       false,
        };
    },

    computed: {
        ...mapStores(useNotificationStore),

        emailError() {
            return this.$v.email.unique === false
                ? 'Dit E-mailadres is al in gebruik'
                : this.getError('email')
            ;
        },

        btnColor() {
            return this.$vuetify.theme.dark
                ? 'black'
                : 'primary'
            ;
        }
    },

    validations() {
        return {
            email:         { required, email, unique: () => ! this.emailInUse  },
            password:      { required, minLength: minLength(6) },
            passwordCheck: { sameAs: sameAs('password') }
        };
    },

    methods: {
        async handleSubmit() {
            let tmpInUse = this.emailInUse;
            this.emailInUse = false;
            this.$v.$touch();
            if (this.$v.$invalid) return;

            this.pending = true;
            try {
                let response = await axios.post('auth/register', { email: this.email, wachtwoord: this.password, wachtwoord_check: this.passwordCheck });
                if (response.data.error) {
                    if (response.data.errors.code === ERROR_EMAIL_ALREADY_IN_USE) {
                        if (tmpInUse && ! this.dialogShown) {
                            this.dialog      = true;
                            this.dialogShown = true;
                        }
                        this.emailInUse = true;
                        this.notificationStore.setNotification(
                            "Dit E-mailadres is al in gebruik.",
                            "error",
                            {
                                action: {
                                    text: "Help!",
                                    callback: () => {
                                        this.dialog      = true;
                                        this.dialogShown = true;
                                    }
                                }
                            }
                        );
                    }
                } else {
                    this.formSuccess = true;
                }
            } catch(e) {
                //eslint-disable-next-line no-console
                console.error(e);
            } finally {
                this.pending = false;
            }
        }
    }
};
</script>

<style lang="scss">
    .form__button {
        margin-top: 20px;
    }

    .form__description {
        .v-card__title {
            font-size: 1.5em;
            font-weight: bold;
        }
    }
    .register {
        &__list {
            margin: 20px 0;
        }

        &__checkbox {
            .v-label {
                font-size: .875em;
            }
        }
        &__show-sm {
            @media (min-width: 769px) {
                display: none;
            }
        }
    }
</style>
